import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import { setLanguage } from '../store/actions/config';

import { ContactoLayout } from '../layouts';
import { usePasswordRecoveryParam } from '../hooks';
import { api } from '../services';

const ContactoPage = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const lang = 'ES';

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language || lang);
  const code = usePasswordRecoveryParam();
  const { fetchLabelContact } = api();

  useEffect(() => {
    let ignore = false;
    setLoading(true);
    const fetchData = async ignore => {
      dispatch(setLanguage(currentLang));

      try {
        let response = null;
        response = await fetchLabelContact(currentLang);
        if (!ignore) {
          setData(response);
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return !loading ? (
    <ContactoLayout
      data={data}
      loading={loading}
      error={error}
      currentLang={currentLang}
    />
  ) : (
    ''
  );
};

export default ContactoPage;
